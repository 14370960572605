/*****************************/
//////////   COLORS  /////////
/*****************************/

$primary-color: #03224c;
$secondary-color: #00a0e3;
$light-color: #f6f9fd;
$contrast-color: #fa5456;
$light-contrast-color: #fef0dd;
$grey: LightSlateGrey;
$light-grey: #d5d6d8;
$white: #fff;
$black: #000;

// CUSTOMIZE PRIMARY COLOR FROM BOOTSTRAP
$blue: #00a0e3 !important;
$red: #fa5456 !important;
$green: #50c878 !important;
$violet: #b97cfc !important;
$yellow: #fdbf3d !important;
$marine: #2748a0 !important;
$darkred: #bf1932;

/*****************************/
//////////   FONTS  //////////
/*****************************/

$title-font: "HindSiliguriBold", sans-serif;
$text-font: "HindSiliguri", sans-serif;
$light-font: "HindSiliguriLight", sans-serif;

@mixin fluid-type($font-min, $font-max) {
	font-size: #{$font-min};

	@media only screen and (min-width: 600px) {
		font-size: calc(#{$font-min} + #{($font-max - $font-min)} * (100vw - 600px) / (1200 - 600));
	}

	@media only screen and (min-width: 1200px) {
		font-size: #{$font-max};
	}
}

.btn-outline-primary {
	background-color: $white;
}

body {
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	color: $primary-color;
	font-family: $text-font;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-rows: 1fr auto;
	height: 100vh;
	letter-spacing: 0.2px;
	background-color: $light-color;
	font-weight: 400;
	@include fluid-type(0.92rem, 0.95rem);
}

.red {
	color: $red;
}

.blue {
	color: $blue;
}

.green {
	color: $green;
}

.grey {
	color: $grey;
}

p {
	font-family: $text-font;
	@include fluid-type(0.92rem, 0.95rem);
	color: $primary-color;
	letter-spacing: 0.2px;
	line-height: 1.2rem;
	font-weight: 400;
}

h1 {
	font-family: $title-font;
	color: $secondary-color;
	letter-spacing: 0.5px;
	@include fluid-type(1.35rem, 1.5rem);
	padding: 0.5rem 0 0 0.5rem;
}

h2 {
	font-family: $title-font;
	color: $primary-color;
	letter-spacing: 0.25px;
	@include fluid-type(1.25rem, 1.3rem);
}

h3 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(1.1rem, 1.15rem);
	margin-top: 1rem;
	letter-spacing: 0.25px;
}

h5 {
	font-family: $text-font;
	color: $primary-color;
	@include fluid-type(0.98rem, 1.01rem);
	letter-spacing: 0.1px;
	padding: 0;
	margin: 0.5rem 0 0.5rem 0;
}

ins {
	text-decoration: underline;
}

.meta {
	color: $grey;
	@include fluid-type(0.91rem, 0.93rem);
	line-height: 1.25rem;
	font-weight: 400;
}

a {
	color: $secondary-color;
}

label {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.95rem);
	color: $primary-color;
	letter-spacing: 0.5px;
	line-height: 1rem;
	font-weight: 300;
}

input[type="checkbox"] {
	// margin-top: 0.1rem;
	margin-right: 0.2rem;
	vertical-align: middle;
	position: relative;
	width: 1.3rem;
	height: 1.3rem;
	color: $primary-color;
	background-color: $white;
	border: 1px solid $grey;
	border-radius: 4px;
	appearance: none;
	outline: 0;
	cursor: pointer;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		display: block;
		bottom: 2px;
		left: 5px;
		width: 0.5rem;
		height: 1.1rem;
		border-style: solid;
		border-color: $white;
		border-width: 0 4px 4px 0;
		transform: rotate(45deg);
		opacity: 0;
	}
	&:checked {
		color: $white;
		border-color: $secondary-color;
		background: $secondary-color;
		&::before {
			opacity: 1;
		}
	}
}

.react-select-container {
	.react-select__control {
		padding: 0.3rem;
	}
	.react-select__multi-value__label {
		@include fluid-type(0.9rem, 0.95rem);
	}
}
ul li {
	display: list-item;
	list-style-type: disc;
}

ol li {
	display: list-item;
	list-style-type: decimal;
}

.colored-background {
	background-color: $light-color;
	padding: 0.5rem;
	margin: 0.5rem 0;
}

b {
	font-weight: 600;
}

.btn {
	border-radius: 10px;
}

.chevron {
	width: 18px;
	height: auto;
}

.center {
	text-align: center;
}

.right {
	float: right;
}

.main-layout {
	width: 100%;
	max-width: 1024px;
	background-color: $white;
	margin: 0 auto;
	padding: 0;
	position: relative;
	min-height: 93vh;
}

.layout {
	padding: 0.5rem;
}

.content-layout {
	margin: 1rem 0.5rem;
	padding: 0;
	position: relative;
}

header {
	margin: 0 0 1rem 0;
	padding: 0;
	width: 100%;
	height: 4rem;
	position: relative;
	background: rgb(2, 0, 36);
	background: linear-gradient(
		150deg,
		rgba(0, 160, 227, 1) 0%,
		rgba(3, 34, 76, 1) 60%,
		rgba(2, 0, 36, 1) 90%
	);
}

.logo {
	width: auto;
	height: 3rem;
	margin: 0.5rem 0 0 0.8rem;
	padding: 0;
}

.amd-logo {
	width: auto;
	height: 4rem;
	margin: 0;
	padding: 0.2rem;
}

.menu-icon {
	width: 1.9rem;
	height: auto;
	color: $white;
	cursor: pointer;
	margin: 1rem 1.5rem 0 0;
	float: right;
}

.user-icon {
	width: auto;
	height: 1.6rem;
	color: $white;
	cursor: pointer;
	margin: 1.1rem 1.2rem 0 0;
	float: right;
	padding: 0;
}

.navigation-bar {
	border: none;
	padding: 0 0 1rem 0;
	border-radius: 20px;
	min-height: 3rem;
	width: 100%;
	margin: 0 0 1.5rem 0;
	border-bottom: solid 2px darken($color: $light-color, $amount: 5);
}

.navigation-text {
	color: white;
	padding: 0;
	margin: 0;
	@include fluid-type(0.8rem, 0.9rem);
	line-height: 1.75rem;
	transition-duration: 0.4s;
	border-radius: 25px;
	font-weight: 300;

	&.blue {
		border: solid 1px $secondary-color;
		background-color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		background-color: $contrast-color;
	}
	&.yellow {
		border: solid 1px $yellow;
		background-color: $yellow;
	}
}

.navigation-block:hover {
	color: $primary-color;
	.navigation-text {
		color: $primary-color;
	}
}

/// FLOW ///

.flow-layout {
	width: 100%;
	margin: 0 auto;
	max-width: 60rem;
}

.separator {
	margin: 1rem 0 1rem 0;
	width: 50%;
	border-bottom: solid 1px darken($color: $light-color, $amount: 4);
}

.social-meta {
	color: lighten($color: $grey, $amount: 20);
	font-size: 0.88rem;
	font-weight: 400;
}

.social-icon-content {
	vertical-align: middle;
	width: 1.25rem;
	height: auto;
	color: $primary-color;
}

.social-like-content {
	vertical-align: middle;
	width: 1.25rem;
	height: auto;
	color: $contrast-color;
}
.social-like-content:hover {
	color: $secondary-color;
}

.floating-button {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 25px;
	right: 25px;
	background-color: $contrast-color;
	border-radius: 50px;
	text-align: center;
	box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.2);
	border: none;
}

.floating-button:hover {
	background-color: $primary-color;
}

.floating-button:focus {
	outline: 0;
}

.floating-icon {
	color: $white;
	width: 30px;
	height: auto;
}

footer {
	grid-row-start: 2;
	grid-row-end: 3;
	background-color: $white;
	border-top: solid 8px $light-color;
	padding: 1rem;
}

.fake-link {
	cursor: pointer;
	font-weight: 600;
}

.error {
	border: solid 1px $contrast-color;
	border-left: solid 6px $contrast-color;
	border-radius: 6px;
	padding: 0.2rem 0.4rem;
	margin: 1rem 0;
	background-color: $white;
	color: $primary-color;
	font-weight: 500;
	font-size: 0.85rem;
	cursor: pointer;
	animation-duration: 1s;
	animation-name: slidein;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

.success {
	border: solid 1px $green;
	border-left: solid 6px $green;
	border-radius: 6px;
	padding: 0.2rem 0.4rem;
	margin: 1rem 0;
	background-color: $white;
	color: $primary-color;
	font-weight: 500;
	font-size: 0.85rem;
	cursor: pointer;
	animation-duration: 1s;
	animation-name: slidein;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

.light-button {
	background: white;
	color: $secondary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.9rem);
	border-radius: 7px;
	text-align: center;
	text-decoration: none;
	padding: 0.25rem 0.5rem;
	margin-top: -0.5rem;
	transition-duration: 0.4s;
	cursor: pointer;
	border: solid 1px $secondary-color;
}

.light-button:hover {
	color: white;
	background-color: $primary-color;
	border: solid 1px $primary-color;
}

.light-button:focus {
	outline: 0;
}

.thumb-my-post {
	width: 100%;
	height: auto;
	max-height: 400px;
	object-fit: cover;
}

.thumb-my-post-on-mobile {
	width: 100%;
	height: 35vw;
	object-fit: cover;
}

.status-my-posts-icon {
	width: auto;
	height: 1.1rem;
	margin-right: 0.4rem;
}

.single-post-medias {
	width: 90%;
	margin: 0 auto;
}

.preview-video {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	transition-duration: 0.4s;
}

.preview-play-video {
	width: 100px;
	height: auto;
	color: $contrast-color;
}

.preview-play-video:hover {
	color: $primary-color;
}

.play-video {
	position: absolute;
	top: 40%;
	left: 50%;
	cursor: pointer;
	transition-duration: 0.4s;
}

/*****************************/
///////////   MODAL  //////////
/*****************************/

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.modal-rgpd {
	width: 80%;
	height: 80%;
	overflow-y: scroll;
	background: white;
	border-radius: 8px;
	padding: 1rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	p {
		text-align: left;
	}
	h4 {
		text-align: left;
		line-height: 1.25rem;
	}
}

.dialog {
	background: white;
	border-radius: 8px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	width: 85%;
	max-width: 37rem;
	height: auto;
	max-height: 75vh;
}

.close-modal {
	float: right;
	border: none;
	font-family: $text-font;
	font-weight: 400;
	@include fluid-type(0.9rem, 1rem);
	color: $primary-color;
	text-align: center;
	border: solid 1px lighten($color: $grey, $amount: 25);
	border-radius: 8px;
	padding: 0 0.5rem;
}

.close-modal:hover {
	color: $secondary-color;
	border: solid 1px $secondary-color;
}

/// REACT SELECT

.basic-multi-select {
	padding: 0;
	margin: 0 0 1rem 0;
}
.select__placeholder {
	font-size: 1.1rem;
	padding: 0.5rem;
}
.select__multi-value,
.select__single-value {
	font-size: 1.15rem;
	padding: 0.5rem;
}
.select__menu-list {
	font-size: 1.1rem;
}

/// TOOLTIP

.tooltip-container {
	position: relative;
}

.tooltip-box {
	width: 100%;
	min-width: 4.5rem;
	text-align: center;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 2px;
	border-radius: 5px;
	top: 3.7rem;
	display: none;
	right: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.tooltip-box.visible {
	display: block;
}

/// RGPD MODAL

.family-outline-badge {
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
	margin: 0.5rem 0rem;
	border-radius: 7px;
	@include fluid-type(0.8rem, 0.85rem);
	font-weight: 400;
	border: none;
	background-color: $white;

	.meta {
		@include fluid-type(0.78rem, 0.8rem);
		line-height: 1.25rem;
	}

	&.blue {
		border: solid 1px $secondary-color;
		color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		color: $violet;
	}
}
